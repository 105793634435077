import React, { Component } from 'react'
import NewsletterForm from './NewsletterForm'

class Contact extends Component {
  render() {
    return (
      <>
        <h1>Stay in touch</h1>
        <p>You can find me around the internet:</p>
        <ul>
          <li>
            <strong>Email</strong>: <a href="mailto:huebler@posteo.net">huebler@posteo.net</a>
          </li>
          <li>
            <strong>Xing</strong>:{' '}
            <a target="_blank" href="https://www.xing.com/profile/Florian_Huebler5">
              Florian Hübler
            </a>
          </li>
          <li>
            <strong>LinkedIn</strong>:{' '}
            <a target="_blank" href="https://linkedin.com/in/florianhuebler">
              florianhuebler
            </a>
          </li>
        </ul>
      </>
    )
  }
}

export default Contact
